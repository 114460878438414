import styled from "styled-components";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStylesHeader = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginLeft: "115px",
            height: "60px",
        },
    })
);

export const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
        buttonList: {
            fontWeight: "bold",
            margin: "7px",
            position: "relative",
            float: "right",
        },
        buttonIcon: {
            marginRight: "5px",
        },
        inputControl: {
            position: "relative",
            width: "100%",
            color: "black",
            height: "3.4rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "16px",
        },
        buttonIconDelete: {
            position: "absolute",
            //textAlign: 'center',
            top: "15px",
            color: "danger",
            height: "27px",
            width: "27px",
        },
        buttonIconUpdate: {
            position: "relative",
            textAlign: "center",
        },
        buttonControl: {
            position: "absolute",
            width: "20rem",
            color: "black",
            height: "100%",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "16px",
        },
        buttonLink: {
            color: "#e9e9e9",
            fontSize: "13px",
        },
        buttonLinkTechnic: {
            fontSize: "13px",
        },
        formControl: {
            position: "absolute",
            width: "92%",
            color: "grey",
            "& label.Mui-focused": {
                color: "#3b8c8c",
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "#3b8c8c",
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "black",
                },
                "&:hover fieldset": {
                    borderColor: "orange",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#3b8c8c",
                },
            },
        },
        TextFieldControl: {
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            backgroundClip: "border-box",
            border: "1px solid transparent",
            borderRadius: "4px",
            boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
            padding: "15px 15px 15px 15px",
            height: "100%",
        },
        colorBackground: {
            backgroundColor: "rgb(247,250,255)",
        },
    })
);

export const useStylesLink = makeStyles((theme: Theme) =>
    createStyles({
        rdcLink: {
            color: "black",
        },
    })
);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        scroll: {
            overflowY: "scroll",
            height: "100vh",
        },
        table: {
            textAlign: "center",
        },
    })
);

export const useStylesSelect = makeStyles((theme: Theme) =>
    createStyles({
        inputControl2: {
            position: "relative",
            width: "100%",
            color: "black",
            height: "3.2rem",
            borderRadius: "4px",
            borderColor: "#AEADAD",
            fontSize: "16px",
            top: "-13px",
        },
        labelControl: {
            position: "relative",
            width: "100%",
            color: "#959393",
            //height: '3.2rem',
            top: "-13px",
            fontSize: "12px",
        },
    })
);

export const Container = styled.div`
    margin: 25px 120px 5px;
`;