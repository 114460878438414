import React, { useEffect, useReducer, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  getStatusStatisticList,
  getStatusStatisticbyMemberList,
} from "../../../redux/actions/memberAction";
import { Button, Col, Heading, Row, Card } from "../../../components";
import Content from "../../../layouts/content";
import { Container } from "./style";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DatePicker from "@mui/lab/DatePicker";
import TextFieldd from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Helmet } from "react-helmet";
import { List } from "@material-ui/icons";
import { SERVICEURL } from "../../../appconfig";
import axios, { AxiosError } from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Nav from "../../../layouts/nav/Nav"
import { useStylesButton, useStylesSelect, useStylesHeader, useStyles, useStylesLink } from './style';

const StatusStatic: React.FC<{}> = ({ }) => {
  const classesHeader = useStylesHeader();
  const classesName = useStylesButton();
  const classesButton = useStylesButton();
  const classesLink = useStylesLink();
  const classesStyle = useStyles();
  const classesSelect = useStylesSelect();

  const dis = useDispatch();
  type serviceResponse = {
    id: number;
    name: string;
  };
  type State = {
    dateStart: Date | null;
    dateEnd: Date | null;
    loading: boolean;
    ikPersonnelName: string;
    ikPersonnelId: number;
    statusName: string;
  };

  const initialState: State = {
    dateStart: new Date(),
    dateEnd: new Date(),
    loading: false,
    ikPersonnelName: "",
    ikPersonnelId: 0,
    statusName: "",
  };

  type Action =
    | { type: "setDateStart"; payload: Date | null }
    | { type: "setDateEnd"; payload: Date | null }
    | { type: "setLoading"; payload: boolean }
    | { type: "setIKPersonnelName"; payload: string }
    | { type: "setIKPersonnelId"; payload: number }
    | { type: "setStatusName"; payload: string };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "setDateStart":
        return {
          ...state,
          dateStart: action.payload,
        };
      case "setDateEnd":
        return {
          ...state,
          dateEnd: action.payload,
        };
      case "setLoading":
        return {
          ...state,
          loading: action.payload,
        };
      case "setIKPersonnelName":
        return {
          ...state,
          ikPersonnelName: action.payload,
        };
      case "setIKPersonnelId":
        return {
          ...state,
          ikPersonnelId: action.payload,
        };
      case "setStatusName":
        return {
          ...state,
          statusName: action.payload,
        };
    }
  };

  const mytoken = localStorage.getItem("token");
  const [state, dispatch] = useReducer(reducer, initialState);

  const { message, statusStatisticList } = useSelector(
    (state: RootStateOrAny) => state.statusStatisticList
  );
  const { statusStatisticbyMemberList } = useSelector(
    (state: RootStateOrAny) => state.statusStatisticbyMemberList
  );

  function getStatusStatistic() {
    dis(getStatusStatisticList({ state }));
  }
  function getStatusStatisticbyMember() {
    dis(getStatusStatisticbyMemberList({ state }));
  }

  const [openWarning, setOpenWarning] = React.useState(false);

  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };

  const [ikPersonnel, setIKPersonnel] = useState<serviceResponse[]>([]);
  const [ikName, setIkName] = React.useState("");

  useEffect(() => {
    if (ikPersonnel.length == 0) {
      axios
        .get(SERVICEURL + "api/Data/GetIKPersonnel?", HEADER)
        .then((res) => {
          if (
            res.data.PersonnelList != undefined &&
            res.data.PersonnelList != undefined
          ) {
            setIKPersonnel(res.data.PersonnelList);
          }
        })
        .catch((reason: AxiosError) => {
          if (reason.response!.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
          } else {
            console.log("Handle else:", reason.message);
          }
        });
    }
  }, []);

  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDateErrorMessage, setStartDateErrorMessage] = useState("");
  const [endDateErrorMessage, setEndDateErrorMessage] = useState("");

  const handleStartDate = (event: any) => {
    if (!event) {
      dispatch({ type: "setDateStart", payload: null });
      setStartDateError(false);
      setStartDateErrorMessage("");
      return;
    }

    const newStartDate = new Date(event);
    
    if (state.dateEnd) {
      if (newStartDate > state.dateEnd) {
        setStartDateError(true);
        setStartDateErrorMessage("Dönem Başlangıç Tarihi, Dönem Bitiş Tarihi'nden büyük olamaz");
      } else {
        setStartDateError(false);
        setStartDateErrorMessage("");
        setEndDateError(false);
        setEndDateErrorMessage("");
      }
    }

    dispatch({
      type: "setDateStart",
      payload: newStartDate,
    });
  };

  const handleEndDate = (event: any) => {
    if (!event) {
      dispatch({ type: "setDateEnd", payload: null });
      setEndDateError(false);
      setEndDateErrorMessage("");
      return;
    }

    const newEndDate = new Date(event);
    
    if (state.dateStart) {
      if (newEndDate < state.dateStart) {
        setEndDateError(true);
        setEndDateErrorMessage("Dönem Bitiş Tarihi, Dönem Başlangıç Tarihi'nden küçük olamaz");
      } else {
        setEndDateError(false);
        setEndDateErrorMessage("");
        setStartDateError(false);
        setStartDateErrorMessage("");
      }
    }

    dispatch({
      type: "setDateEnd",
      payload: newEndDate,
    });
  };

  const handleChangeIKPersonnel = (event: any, newValue: any) => {
    if (newValue == null) {
      newValue = 0;
      dispatch({
        type: "setIKPersonnelId",
        payload: newValue,
      });
      setIkName("");
    }
    if (newValue != null && newValue != undefined && newValue != 0) {
      dispatch({
        type: "setIKPersonnelId",
        payload: newValue.id,
      });
      setIkName(newValue.name);
    }
  };

  //Manuel tarih girildiğinde saat boş kalıp backendde 1 gün eksik dönüyor. Bu nedenle manuel saat eklendi.
  var pDate = state.dateStart?.toLocaleDateString();
  var dd = state.dateStart?.getDate();
  var mm = Number(state.dateStart?.getMonth()) + 1;
  var yyyy = state.dateStart?.getFullYear();
  var datestart = mm + "/" + dd + "/" + yyyy + " " + "5:30:00";
  var sdate = new Date(datestart);

  var pDate = state.dateEnd?.toLocaleDateString();
  var dd = state.dateEnd?.getDate();
  var mm = Number(state.dateEnd?.getMonth()) + 1;
  var yyyy = state.dateEnd?.getFullYear();
  var dateend = mm + "/" + dd + "/" + yyyy + " " + "22:30:00";
  var edate = new Date(dateend);

  if (
    state.dateStart != null &&
    state.dateEnd != null &&
    state.dateStart.getFullYear() != 1970 &&
    state.dateEnd.getFullYear() != 1970
  ) {
    state.dateStart = sdate;
    state.dateEnd = edate;
  }
  if (state.dateStart != null &&
    state.dateStart.getFullYear() === 1970) {
    state.dateStart = new Date();
  }
  if (
    state.dateEnd != null &&
    state.dateEnd.getFullYear() === 1970) {
    state.dateEnd = new Date();
  }

  const handleStatusName = (event: any) => {
    const newValue = event.target.value.toString();

    dispatch({
      type: "setStatusName",
      payload: newValue,
    });
  };

  const getStatusStatisticTable = () => {
    if (
      state.dateStart != null &&
      state.dateEnd != null &&
      state.dateStart.getFullYear() != 1970 &&
      state.dateEnd.getFullYear() != 1970
      // state.statusName != ""
    ) {
      dispatch({
        type: "setLoading",
        payload: true,
      });
      getStatusStatistic();
      //state.techGroup = technic;
      // if (state.ikPersonnelId == 0 || state.ikPersonnelId == null) {
      //     getStatusStatistic();
      //     document.getElementById("statusStatisticbyMemberTable")!.style.visibility = 'hidden';
      //     document.getElementById("statusStatisticTable")!.style.visibility = 'visible';

      // }
      // // getStatusStatistic();

      // if (state.ikPersonnelId != 0 && state.ikPersonnelId != null) {
      //     getStatusStatisticbyMember();
      //     document.getElementById("statusStatisticbyMemberTable")!.style.visibility = 'visible';
      //     document.getElementById("statusStatisticTable")!.style.visibility = 'hidden';
      //     var tableHeight = document.getElementById("statusStatisticTable")?.offsetHeight;
      //     document.getElementById("statusStatisticbyMemberTable")!.style.marginTop = '-'+tableHeight?.toString()+'px';
      //     state.ikPersonnelName=ikName;
      // }
    } else {
      setOpenWarning(true);
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarning(false);
  };

  interface GridCellColorProps {
    value: string;
    params: any;
  }

  interface GridCellExpandProps {
    value: string;
    width: number;
  }

  function isOverflown(element: Element): boolean {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  function renderCellMember(params: GridRenderCellParams<string>) {
    return <GridCellMember value={params.value || ""} params={params} />;
  }

  const GridCellMember = React.memo(function GridCellExpand(
    props: GridCellColorProps
  ) {
    const { value, params } = props;
    return (
      <a href="#memberName">
        <Button color="light" width={360}>
          <Typography className={classesButton.buttonLinkTechnic}>
            <strong>{value}</strong>
          </Typography>
        </Button>
      </a>
    );
  });

  const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps
  ) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current!);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent: KeyboardEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
          setShowFullCell(false);
        }
      }

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);

    return (
      <Box
        ref={wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          alignItems: "center",
          //  lineHeight: '24px',
          width: 1,
          height: 1,
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        <Box
          ref={cellValue}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });
  function renderCellExpand(params: GridRenderCellParams<string>) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }
  useEffect(() => {
    dispatch({
      type: "setLoading",
      payload: false,
    });
  }, [message, statusStatisticbyMemberList, statusStatisticList]);

  return (
    <>
      <Helmet>
        <title>RDC Board - Silme/Güncelleme İstatistikleri</title>
      </Helmet>
      <div className={classesStyle.scroll}>
        <Nav />
        <div className={classesName.colorBackground}>
          <Content>
            <Heading as="h5" mb="5px" className={classesHeader.header}>
              Silme/Güncelleme İstatistikleri
            </Heading>
            <Container className={classesName.TextFieldControl}>
              <Row>
                <Col lg={3} md sm xs mb={25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dönem Başlangıç Tarihi"
                      value={state.dateStart}
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextFieldd 
                          {...params} 
                          fullWidth 
                          error={startDateError}
                          helperText={startDateErrorMessage}
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Col>
                <Col lg={3} md sm xs mb={25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dönem Bitiş Tarihi"
                      value={state.dateEnd}
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <TextFieldd
                          {...params}
                          fullWidth
                          error={endDateError}
                          helperText={endDateErrorMessage}
                        />
                      )}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Col>
                <Col lg={3} md sm xs mb={25}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Aksiyon
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="demo-simple-select-outlined-label"
                      label="Durum İstatistikleri *"
                      id="exp"
                      onChange={handleStatusName}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                    >
                      <MenuItem value={""}></MenuItem>
                      <MenuItem value={"DeletedStatus"}>Durum Silme</MenuItem>
                      <MenuItem value={"UpdatedStatus"}>
                        Durum Güncelleme
                      </MenuItem>
                      <MenuItem value={"DeletedPersonel"}>Aday Silme</MenuItem>
                      <MenuItem value={"SalaryDetail"}>Maaş Listeleme</MenuItem>
                      <MenuItem value={"FinanceLog"}>Finans Log</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3} md sm xs mb={25}>
                  {Object.values(ikPersonnel).length > 0 && (
                    <Autocomplete
                      options={ikPersonnel}
                      getOptionLabel={(options) => options.name}
                      onChange={handleChangeIKPersonnel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Personel"
                          fullWidth
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option?.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      filterOptions={(options, { inputValue }) => {
                        return options.filter((option) =>
                          option.name
                            .toLocaleLowerCase("tr-TR")
                            .includes(inputValue.toLocaleLowerCase("tr-TR"))
                        );
                      }}
                    />
                  )}
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button
                    iconButton
                    size="lg"
                    color="light"
                    className={classesButton.buttonList}
                    onClick={getStatusStatisticTable}
                  >
                    <List
                      width={18}
                      height={18}
                      className={classesButton.buttonIcon}
                    />
                    LİSTELE
                  </Button>
                  <Snackbar
                    open={openWarning}
                    autoHideDuration={1000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose}
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      Tarih ve Durum Seçeneğini boş geçilemez.
                    </Alert>
                  </Snackbar>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={state.loading}
                  // onClick={handleClose}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Col>
              </Row>

              <br />
              <br />
              <div id="statusStatisticTable">
                {Object.values(statusStatisticList) !== null ||
                  Object.values(statusStatisticList) !== undefined}

                {Object.values(statusStatisticList).length > 0 && (
                  <Card>
                    <DataGrid
                      autoHeight={true}
                      rowHeight={25}
                      rows={Object.values(statusStatisticList?.data).map(
                        (item: any, index: any) => ({
                          id: index,
                          statusName: item.StatusName ?? "",
                          ikPersonnelName: item.IkMemberName ?? "",
                          memberName: item.MemberName,
                          date: item.DeletedDate ?? item.UpdatedDate,
                          // detail:item.CustomerName +"-"+ item.SubStatusName +"-"+ item.PositionName +"-"+ item.ProjectName,
                          // ikPersonnelId: item.ikPersonnelId,
                          // count: item.count,
                          detail: item.Detail,
                          link: item.RdcLink,
                        })
                      )}
                      columns={[
                        { field: "id", headerName: "ID", hide: true },
                        {
                          field: "statusName",
                          headerName: "Aksiyon",
                          flex: 0.35,
                        },
                        { field: "date", headerName: "Tarih", flex: 0.3 },
                        {
                          field: "ikPersonnelName",
                          headerName: "Ik Ad-Soyad",
                          flex: 0.45,
                        },
                        {
                          field: "memberName",
                          headerName: "Aday Ad-Soyad",
                          flex: 0.4,
                          renderCell: (params: any) => [
                            <a
                              href={`https://rdcboard.com/employee/detail/${params.row.link}`}
                              target="_blank"
                              className={classesLink.rdcLink}
                            >
                              {params.value}
                            </a>,
                          ],
                        },
                        {
                          field: "detail",
                          headerName: "",
                          flex: 1,
                          renderCell: renderCellExpand,
                        },

                        // { field: "ikPersonnelId", headerName: "Personel Ad-Soyad", hide: true, },
                        // { field: "count", headerName: "Toplam Sayı", flex: 1, renderCell: renderCellMember },

                        // {
                        //     field: "actions",
                        //     type: "actions",
                        //     headerName: "İncele",
                        //     width: 250,
                        //     getActions: (params: any) => [
                        //         <GridActionsCellItem
                        //             icon={
                        //                 <RemoveRedEyeIcon style={{ color: "#FF8D29" }} />
                        //             }
                        //             label="Detaya Git"
                        //             onClick={() => handleDetailTable(params.row)}
                        //         />,
                        //     ],
                        // },
                      ]}
                    />
                  </Card>
                )}
              </div>
              <br />
            </Container>
          </Content>
        </div>
      </div>
    </>
  );
};

export default StatusStatic;