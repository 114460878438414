import { TrendingUp, DollarSign } from "react-feather";
const roles = localStorage.getItem("roles");

const menus = [
  // {
  //     id: 1,
  //     label: "Sözleşmeler",
  //     url: "/",
  //     Icon: Search,
  //     submenu: [
  //         // {
  //         //     id: 5,
  //         //     label: "Sözleşme",
  //         //     url: "/",
  //         // },
  //         // {
  //         //     id: 6,
  //         //     label: "Sözleşme Detay",
  //         //     url: "/",
  //         // }
  //     ],
  // },

  {
    id: 2,
    label: "Maliyetler",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      // {
      //     id: 11,
      //     label: "Fatura",
      //     url: "/",
      // },
      // {
      //     id: 12,
      //     label: "Hakediş",
      //     url: "/",
      // } ,
      roles?.includes("FinanceSalary")
        ? {
          id: 13,
          label: "Maaş Maliyetleri",
          url: "/salarylist",
        }
        : {
          id: 13,
          label: "",
          url: "/",
        },
      roles?.includes("FinanceCurrentDebt")
        ? {
          id: 14,
          label: "Cari Borç",
          url: "/currentDebt",
        }
        : {
          id: 14,
          label: "",
          url: "/",
        },
      roles?.includes("FinanceSalary")
        ? {
          id: 15,
          label: "Personel-Maaş",
          url: "/personalSalary",
        }
        : {
          id: 15,
          label: "",
          url: "/",
        },
      roles?.includes("FinanceOssSetCard")
        ? {
          id: 16,
          label: "ÖSS-SetCard",
          url: "/ossSetcard",
        }
        : {
          id: 16,
          label: "",
          url: "/",
        },
      roles?.includes("salarySummary")
        ? {
          id: 36,
          label: "Maaş Özeti",
          url: "/salarySummary",
        }
        : {
          id: 36,
          label: "",
          url: "/",
        },
    ],
  },
  {
    id: 7,
    label: "Bütçe",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      roles?.includes("budgetList")
        ? {
          id: 71,
          label: "Bütçe Giriş/Listeleme",
          url: "/budgetList",
        }
        : {
          id: 71,
          label: "",
          url: "/",
        },
      roles?.includes("budgetReport")
        ? {
          id: 72,
          label: "Bütçe Tahminleme",
          url: "/budgetForecast",
        }
        : {
          id: 72,
          label: "",
          url: "/",
        },
      roles?.includes("budgetReport")
        ? {
          id: 73,
          label: "Bütçe Raporlama",
          url: "/budgetReport",
        }
        : {
          id: 73,
          label: "",
          url: "/",
        },
    ],
  },
  {
    id: 3,
    label: "Karlılık",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      roles?.includes("profitability")
        ? {
          id: 31,
          label: "Karlılık Düzeltme ",
          url: "/profitability",
        }
        : {
          id: 31,
          label: "",
          url: "/",
        },
      roles?.includes("netting")
        ? {
          id: 32,
          label: "Mahsuplaşma",
          url: "/netting",
        }
        : {
          id: 32,
          label: "",
          url: "/",
        },

      roles?.includes("selectionPlacementProgress")
        ? {
          id: 35,
          label: "Seçme/Yerleştirme Hakediş",
          url: "/SelectionPlacementProgress",
        }
        : {
          id: 35,
          label: "",
          url: "/",
        },
      roles?.includes("projectProgressPayment")
        ? {
          id: 36,
          label: "Proje Hakediş",
          url: "/projectProgressPayment",
        }
        : {
          id: 36,
          label: "Proje Hakediş",
          url: "/projectProgressPayment",
        },
      roles?.includes("profitabilitySalary")
        ? {
          id: 38,
          label: "Karlılık Maaş",
          url: "/profitabilitySalary",
        }
        : {
          id: 38,
          label: "Karlılık Maaş",
          url: "/profitabilitySalary",
        },

    ],
  },
  {
    id: 4,
    label: "Rapor",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      roles?.includes("FinanceCurrentDebtReport")
        ? {
          id: 41,
          label: "Cari Borç Raporlama",
          url: "/currentDebtReport",
        }
        : {
          id: 41,
          label: "",
          url: "/",
        },
      roles?.includes("FinanceSalaryCalculate")
        ? {
          id: 42,
          label: "Maaş Hesaplama",
          url: "/salaryCalculator",
        }
        : {
          id: 42,
          label: "",
          url: "/",
        },
      roles?.includes("FinanceCurrentDebtPeriodReport")
        ? {
          id: 43,
          label: "Cari Dönem Raporlama",
          url: "/currentPeriodReport",
        }
        : {
          id: 43,
          label: "",
          url: "/",
        },
      roles?.includes("ReportDeleteUpdate")
        ? {
          id: 44,
          label: "Silme/Güncelleme İstatistikleri",
          url: "/stateStatic",
        }
        : {
          id: 44,
          label: "",
          url: "/",
        },
      {
        id: 45,
        label: "Faturalar Rapor",
        url: "/invoiceReport",
      },

      roles?.includes("ReportDeleteUpdate")
        ? {
          id: 46,
          label: "Karlılık",
          url: "",
          submenu: [
            roles?.includes("profitabilityReportDetail")
              ? {
                id: 33,
                label: "Karlılık Raporu Detayı",
                url: "/profitabilityReportDetail",
              }
              : {
                id: 33,
                label: "",
                url: "/",
              },
            roles?.includes("sumProfitabilityReport")
              ? {
                id: 34,
                label: "Karlılık Raporu-Özet",
                url: "/sumProfitabilityReport",
              }
              : {
                id: 34,
                label: "",
                url: "/",
              },
            roles?.includes("profitabilityNew")
              ? {
                id: 37,
                label: "",
                url: "/profitabilityNew",
              }
              : {
                id: 37,
                label: "Karlılık Trend",
                url: "/profitabilityNew",
              },
            roles?.includes("profitabilityReportDetail")
              ? {
                id: 33,
                label: "Proje Rapor",
                url: "/projectReport",
              }
              : {
                id: 33,
                label: "",
                url: "/",
              },

          ]
        }
        : {
          id: 44,
          label: "",
          url: "/",
        }

    ],


  },
  {
    id: 5,
    label: "Tanım",
    url: "/",
    Icon: DollarSign,
    submenu: [
      roles?.includes("FinanceCosts")
        ? {
          id: 51,
          label: "Masraf Merkezi",
          url: "/costCenter",
        }
        : {
          id: 51,
          label: "",
          url: "/",
        },

    ],
  },
  {
    id: 6,
    label: "Parametre",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      roles?.includes("FinanceParameterTufe")
        ? {
          id: 61,
          label: "TÜFE Parametre Tanımlama",
          url: "/parameterDefinition",
        }
        : {
          id: 61,
          label: "",
          url: "/",
        },
      roles?.includes("financeParameter")
        ? {
          id: 62,
          label: "Parametre",
          url: "/parameter",
        }
        : {
          id: 62,
          label: "",
          url: "/",
        },
      roles?.includes("financeParameter")
        ? {
          id: 63,
          label: "Firma Tanımlama",
          url: "/companyIdentification",
        }
        : {
          id: 63,
          label: "",
          url: "/",
        },
      roles?.includes("financeParameter")
        ? {
          id: 64,
          label: "Merkez Bankası Kurları",
          url: "/centralBankRates",
        }
        : {
          id: 64,
          label: "",
          url: "/",
        },
      roles?.includes("financeParameter")
        ? {
          id: 65,
          label: "Resmi Tatiller",
          url: "/publicHolidays",
        }
        : {
          id: 65,
          label: "",
          url: "/",
        },
    ],
  },
  {
    id: 8,
    label: "Freelance",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      roles?.includes("financeFreelanceAllowance")
        ? {
          id: 81,
          label: "Freelance Hakediş",
          url: "/freelanceAllowance",
        }
        : {
          id: 81,
          label: "",
          url: "/",
        },
      roles?.includes("financeFreelanceOrder")
        ? {
          id: 82,
          label: "Freelance Sipariş",
          url: "/freelanceOrder",
        }
        : {
          id: 82,
          label: "",
          url: "/",
        },
    ],
  },
  {
    id: 9,
    label: "Maaş Çalışma",
    url: "/",
    Icon: TrendingUp,
    submenu: [
      roles?.includes("financeSalaryEntrance")
        ? {
          id: 91,
          label: "Maaş Giriş",
          url: "/salaryEntrance",
        }
        : {
          id: 91,
          label: "",
          url: "/",
        },
      roles?.includes("financeSalaryEntrance")
        ? {
          id: 91,
          label: "Maaş Kontrol",
          url: "/salaryControl",
        }
        : {
          id: 92,
          label: "",
          url: "/",
        },
    ],

  },
]
export default menus;
