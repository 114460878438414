import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import "./App.css";
import Preloader from "./layouts/preloader/Preloader";
import NotFound from "./pages/notFound/NotFound";
import PersonnelRegistration from "./pages/personnellRegistration/PersonnelRegistration";
import { logoutAllTabs } from "./components/src/header/profile-dropdown";
import StatusStatic from "./pages/report/statusStatic/StatusStatic";
import CentralBankRates from "./pages/centralBankRates/CentralBankRates";
import ProjectReport from "./pages/projectReport/projectReport";

const Login = lazy(() => import("./pages/login/Login"));
const Help = lazy(() => import("./pages/help/Help"));
const Profile = lazy(() => import("./pages/profile-view/profile-view"));
const Home = lazy(() => import("./pages/home"));
const CurrentDebt = lazy(() => import("./pages/currentDebt/CurrentDebt"));
const CostCenter = lazy(() => import("./pages/costCenter/CostCenter"));
const AddCostCenter = lazy(() => import("./pages/addCostCenter/AddCostCenter"));
const SalaryList = lazy(() => import("./pages/salary/SalaryList"));
const PersonalSalary = lazy(
  () => import("./pages/personalSalary/PersonalSalary")
);
const CurrentDebtReport = lazy(
  () => import("./pages/report/currentDebtReport/CurrentDebtReport")
);
const ParameterDefinition = lazy(
  () => import("./pages/parameterDefinition/tufeParameter/TufeParameter")
);

const FreelanceAllowance = lazy(
  () => import("./pages/freelance/freelanceAllowance/FreelanceAllowance")
);
const FreelanceOrder = lazy(
  () => import("./pages/freelance/freelanceOrder/FreelanceOrder")
);

const ProjectProgressPayment = lazy(
  () =>
    import(
      "./pages/projectProgressPayment/freelanceAllowance/ProjectProgressPayment"
    )
);
const ProjectProgressPaymentDetail = lazy(
  () =>
    import(
      "./pages/projectProgressPayment/freelanceAllowance/ProjectProgressPaymentDetail"
    )
);

const SalaryCalculator = lazy(
  () => import("./pages/report/salaryCalculator/SalaryCalculator")
);
const CurrentPeriodReport = lazy(
  () => import("./pages/report/currentPeriodReport/CurrentPeriodReport")
);
const ProfitabilityCorrection = lazy(
  () => import("./pages/profitability/profitabilityCorrection")
);
const ProfitabilityReportDetail = lazy(
  () => import("./pages/profitabilityReportDetail/ProfitabilityReportDetail")
);
const SumProfitabilityReport = lazy(
  () => import("./pages/sumProfitabilityReport/SumProfitabilityReport")
);
const SelectionPlacementProgress = lazy(
  () => import("./pages/SelectionPlacementProgress/selectionPlacementProgress")
);

const ProfitabilityNew = lazy(
  () => import("./pages/profitabilityNew/profitabilityNew")
);
const ProfitabilitySalary = lazy(
  () => import("./pages/profitabilitySalary/profitabilitySalary")
);

const Netting = lazy(() => import("./pages/netting/Netting"));
const BudgetList = lazy(() => import("./pages/budget/budget"));
const SalarySummary = lazy(() => import("./pages/salarySummary/SalarySummary"));
const Parameter = lazy(() => import("./pages/parameter/Parameter"));
const PublicHolidays = lazy(() => import("./pages/publicHolidays/publicHolidays"));
const CompanyIdentification = lazy(
  () => import("./pages/companyIdentification/CompanyIdentification")
);
const centralBankRates = lazy(() => import("./pages/centralBankRates/CentralBankRates"));
const BudgetReport = lazy(() => import("./pages/budget/budgetReport"));
const BudgetForecast = lazy(() => import("./pages/budget/budgetForecast"));

const SalaryEntrance = lazy(
  () => import("./pages/salaryCalculate/salaryEntrance/SalaryEntrance")
);
const SalaryEntranceHr = lazy(
  () => import("./pages/salaryCalculate/salaryEntrance/SalaryEntranceHr")
);

const InvoiceReport = lazy(
  () => import("./pages/report/invoiceReport/InvoiceReport")
);
const projectReport = lazy(
  () => import("./pages/projectReport/projectReport")
);
const App: React.FC = () => {
  useEffect(() => {
    logoutAllTabs();
  }, []);

  const token = localStorage.getItem("token");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);

  if (token == null) {
    logoutAllTabs();
  }
  useEffect(() => {
    if (token != null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [token]);

  //
  return (
    <div>
      <Router>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              exact
              path="/"
              component={() =>
                isLoggedIn ? <Home /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/personnelregister"
              component={() =>
                isLoggedIn ? (
                  <PersonnelRegistration />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/help"
              component={() =>
                isLoggedIn ? <Help /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/profile"
              component={() =>
                isLoggedIn ? <Profile /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/currentDebt"
              component={() =>
                isLoggedIn ? <CurrentDebt /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/costCenter"
              component={() =>
                isLoggedIn ? <CostCenter /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/addCostCenter"
              component={() =>
                isLoggedIn ? <AddCostCenter /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/salarylist"
              component={() =>
                isLoggedIn ? <SalaryList /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/personalSalary"
              component={() =>
                isLoggedIn ? <PersonalSalary /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/currentDebtReport"
              component={() =>
                isLoggedIn ? <CurrentDebtReport /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/currentPeriodReport"
              component={() =>
                isLoggedIn ? <CurrentPeriodReport /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/parameter"
              component={() =>
                isLoggedIn ? <Parameter /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/publicHolidays"
              component={() =>
                isLoggedIn ? <PublicHolidays /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/companyIdentification"
              component={() =>
                isLoggedIn ? (
                  <CompanyIdentification />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/centralBankRates"
              component={() =>
                isLoggedIn ? (
                  <CentralBankRates />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/login"
              component={() =>
                isLoggedIn == false ? <Login /> : <Redirect to="/" />
              }
            />
            <Route
              exact
              path="/parameterDefinition"
              component={() =>
                isLoggedIn ? <ParameterDefinition /> : <Redirect to="/login" />
              }
            />

            <Route
              exact
              path="/freelanceAllowance"
              component={() =>
                isLoggedIn ? <FreelanceAllowance /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/freelanceOrder"
              component={() =>
                isLoggedIn ? <FreelanceOrder /> : <Redirect to="/login" />
              }
            />

            <Route
              exact
              path="/projectProgressPayment"
              component={() =>
                isLoggedIn ? (
                  <ProjectProgressPayment />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />

            <Route
              exact
              path="/salaryEntrance"
              component={() =>
                isLoggedIn ? <SalaryEntrance /> : <Redirect to="/login" />
              }
            />

            <Route
              exact
              path="/salaryControl"
              component={() =>
                isLoggedIn ? <SalaryEntranceHr /> : <Redirect to="/login" />
              }
            />

            <Route
              exact
              path="/projectProgressPaymentDetail/:projectId"
              component={() =>
                isLoggedIn ? (
                  <ProjectProgressPaymentDetail />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />

            <Route
              exact
              path="/salaryCalculator"
              component={() =>
                isLoggedIn ? <SalaryCalculator /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/profitability"
              component={() =>
                isLoggedIn ? (
                  <ProfitabilityCorrection />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/profitabilityReportDetail"
              component={() =>
                isLoggedIn ? (
                  <ProfitabilityReportDetail />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/sumProfitabilityReport"
              component={() =>
                isLoggedIn ? (
                  <SumProfitabilityReport />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/netting"
              component={() =>
                isLoggedIn ? <Netting /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/SelectionPlacementProgress"
              component={() =>
                isLoggedIn ? (
                  <SelectionPlacementProgress />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              exact
              path="/budgetList"
              component={() =>
                isLoggedIn ? <BudgetList /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/salarySummary"
              component={() =>
                isLoggedIn ? <SalarySummary /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/budgetReport"
              component={() =>
                isLoggedIn ? <BudgetReport /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/budgetForecast"
              component={() =>
                isLoggedIn ? <BudgetForecast /> : <Redirect to="/login" />
              }
            />

            <Route
              exact
              path="/stateStatic"
              component={() =>
                isLoggedIn ? <StatusStatic /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/invoiceReport"
              component={() =>
                isLoggedIn ? <InvoiceReport /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/profitabilityNew"
              component={() =>
                isLoggedIn ? <ProfitabilityNew /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/profitabilitySalary"
              component={() =>
                isLoggedIn ? <ProfitabilitySalary /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/projectReport"
              component={() =>
                isLoggedIn ? <ProjectReport /> : <Redirect to="/login" />
              }
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
  // else return (
  //   <div>
  //     <Router>
  //       <Suspense fallback={<Preloader />}>
  //         <Switch>
  //           <Route exact path="/" component={Login} />
  //           <Route exact path="/*" component={Login} />
  //           <Route exact path="/login" component={Login} />
  //           {/* </Switch>
  //   <Switch> */}
  //           {/* <Route component={NotFound}/> */}
  //         </Switch>
  //       </Suspense>
  //     </Router>
  //   </div>
  // )
};

export default App;