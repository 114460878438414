import styled, { themeGet, css, } from "../../shared/styled";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface TableCellProps {
  isCompleted?: boolean;
  children: React.ReactNode;
}

export const useStylesButton = makeStyles((theme: Theme) =>
  createStyles({
    buttonList: {
      fontWeight: "bold",
      margin: "7px",
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonTransfer: {
      fontWeight: "bold",
      margin: "7px",
    },
    buttonSave: {
      margin: "7px",
      fontWeight: "bold",
      position: "relative",
      float: "right",
    },
    buttonIcon: {
      marginRight: "5px",
    },
    buttonIconDelete: {
      position: "relative",
      textAlign: "center",
      color: "danger",
    },
    buttonIconUpdate: {
      position: "relative",
      textAlign: "center",
    },
    TextFieldControl: {
      overflowWrap: "break-word",
      backgroundColor: "rgb(255, 255, 255)",
      backgroundClip: "border-box",
      border: "1px solid transparent",
      borderRadius: "4px",
      boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
      padding: "15px 15px 15px 15px",
      height: "100%",
    },
    colorBackground: {
      backgroundColor: "rgb(247,250,255)",
    },
  })
);

export const useStylesHeader = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginLeft: "117px",
      height: "40px"
    },
    scroll: {
      overflowY: "scroll",
      height: "100vh",
    },
    TextFieldControl: {
      overflowWrap: "break-word",
      backgroundColor: "rgb(255, 255, 255)",
      backgroundClip: "border-box",
      border: "1px solid transparent",
      borderRadius: "4px",
      boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
      padding: "15px 15px 15px 15px",
      height: "100%",
    },
    colorBackground: {
      backgroundColor: "rgb(247,250,255)",
    },
  })
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    scroll: {
      overflowY: "scroll",
      height: "100vh",
    },
    table: {
      textAlign: "center",
    },
    flex:{
      disflay:'flex',
      justifyContent:'space-between'
    }
  })
);

export const Container = styled.div`
    margin: 50px 110px 5px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
`;

export const TableHeader = styled.th`
    padding: 12px 15px;
    background-color: white;  
    color: black;  
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
`;


export const TableCell = styled.td<TableCellProps & { isTotalRow?: boolean }>`
  padding: 12px 15px;
  border: 1px solid #ddd;

  ${({ isCompleted, isTotalRow }) =>
    isTotalRow
      ? css`
          color: black;  
        `
      : isCompleted
        ? css`
          color: green;  
        `
        : css`
          color: blue; 
        `}
`;

export const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2; 
    }
    &:hover {
        background-color: #ddd;  
    }
`;